<template>
    <div class="welcome-route">
        <clouds v-if="welcomeTheme === 'CLOUDS'" />
        <astypalaia v-else-if="welcomeTheme === 'ASTYPALAIA'" />
        <clouds-with-gifts v-else />
        <language-select-unauthorized class="language-selector" />
        <div class="welcome-container">
            <div class="welcome-title">
                <b-img
                    :src="branding.primaryLogoUrl"
                    class="mx-2 pt-2"
                    alt=" "
                />
            </div>
            <div class="welcome-text">
                <span>{{ $t('welcomeRoute.welcomeText[0]') }}</span>
                <span>{{ $t('welcomeRoute.welcomeText[1]') }}</span>
            </div>
            <div class="welcome-actions">
                <router-link to="/free-game" class="custom-btn new-player">
                    {{ $t('welcomeRoute.newPlayer') }}
                </router-link>
                <div class="or-container">
                    <hr><span>{{ $t('welcomeRoute.or') }}</span><hr>
                </div>
                <router-link :to="'/login'" class="custom-btn login">
                    {{ $t('welcomeRoute.login') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import LanguageSelectUnauthorized from '@/components/LanguageSelectUnauthorized'
    import CloudsWithGifts from '@/components/welcome-backgrounds/CloudsWithGifts'
    import Clouds from '@/components/welcome-backgrounds/Clouds'
    import Astypalaia from '@/components/welcome-backgrounds/Astypalaia'
    import statsHelper from '@/mixins/statsHelper'
    export default {
        components: {
            LanguageSelectUnauthorized,
            CloudsWithGifts,
            Clouds,
            Astypalaia
        },
        mixins: [statsHelper],
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            welcomeTheme() {
                return this.$store.getters.getWelcomeTheme
            }
        },
        beforeMount() {
            if (this.$route.query.sc) {
                this.$store.commit('setScanCode', this.$route.query.sc)
                this.scanQrSpot(this.$route.query.sc, 'QR_SCAN', 'HOME')
            }
        }
    }
</script>

<style scoped>
.welcome-route {
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
}

.language-selector {
    position: absolute;
    top: 1%;
    left: 2%;
}

.welcome-container {
    color: var(--white);
    padding: 3rem 0 1rem;
    z-index: 1;
    max-width: 677px;
}

.welcome-title {
    margin: 0 auto;
}

.welcome-title img {
    height: 95px;
}

.welcome-text {
    margin: 4rem auto 0;
    font-size: 1.1rem;
    line-height: 1.5;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.welcome-text span:nth-child(2) {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.25rem;
    color: var(--white);
    text-shadow: 0 0 0 rgb(255 255 255 / 70%);
}

.welcome-actions {
    margin: 3rem auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
    max-width: 555px;
}

.custom-btn {
    max-width: 420px;
    width: 75%;
    height: 60px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    font-size: 1.1rem;
    letter-spacing: 0.3em;
    font-weight: 500;
    background: #193674;
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    color: var(--white);
    text-decoration: none;
}

.custom-btn:hover {
    background: #f1b12b;
}

.custom-btn:active {
    transform: scale(0.9);
}

.or-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

hr {
    width: 80px;
    background: var(--white);
}

@media screen and (min-width: 768px) {
    .language-selector {
        left: 1%;
    }

    .welcome-text {
        margin: 4rem auto 0;
        font-size: 1.5rem;
        width: 100%;
    }

    .welcome-text span:nth-child(2) {
        font-size: 1.75rem;
    }

    .welcome-actions {
        margin: 4rem auto 0;
        gap: 2rem;
    }

    .custom-btn {
        height: 70px;
        font-size: 1.25rem;
        width: 85%;
        max-width: 360px;
    }

    .or-container {
        gap: 2rem;
    }
}

</style>
